/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProductUnifiedProductsViewQueryVariables = {
    id: string;
};
export type ProductUnifiedProductsViewQueryResponse = {
    readonly product: {
        readonly id: string;
        readonly styleName: string | null;
        readonly styleNumber: string | null;
        readonly variants: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly hexCode: string | null;
                    readonly variantName: string | null;
                    readonly variantCode: string | null;
                    readonly subvariants: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                                readonly name: string | null;
                                readonly secondary: string | null;
                                readonly gtin: string | null;
                            } | null;
                        } | null>;
                    } | null;
                } | null;
            } | null>;
        } | null;
    } | null;
};
export type ProductUnifiedProductsViewQuery = {
    readonly response: ProductUnifiedProductsViewQueryResponse;
    readonly variables: ProductUnifiedProductsViewQueryVariables;
};



/*
query ProductUnifiedProductsViewQuery(
  $id: ID!
) {
  product(id: $id) {
    id
    styleName
    styleNumber
    variants {
      edges {
        node {
          hexCode
          variantName
          variantCode
          subvariants {
            edges {
              node {
                id
                name
                secondary
                gtin
              }
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "product",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "styleName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "styleNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductVariantConnection",
        "kind": "LinkedField",
        "name": "variants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductVariantEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductVariant",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hexCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "variantName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "variantCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductSubvariantConnection",
                    "kind": "LinkedField",
                    "name": "subvariants",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductSubvariantEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductSubvariant",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "secondary",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "gtin",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductUnifiedProductsViewQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductUnifiedProductsViewQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9e83560231b98a1a03e05583dba74895",
    "id": null,
    "metadata": {},
    "name": "ProductUnifiedProductsViewQuery",
    "operationKind": "query",
    "text": "query ProductUnifiedProductsViewQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    id\n    styleName\n    styleNumber\n    variants {\n      edges {\n        node {\n          hexCode\n          variantName\n          variantCode\n          subvariants {\n            edges {\n              node {\n                id\n                name\n                secondary\n                gtin\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5e43c9940c5bace8a2a1f19c809fcbeb';
export default node;
