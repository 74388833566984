import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../ui-kit/src/commons/graphql";
import {UpdateOrderUnifiedProductDataMutation} from "./__generated__/UpdateOrderUnifiedProductDataMutation.graphql";

export default createMutationBoilerplate<UpdateOrderUnifiedProductDataMutation>(
  graphql`
    mutation UpdateOrderUnifiedProductDataMutation($input: UpdateOrderUnifiedProductDataInput!) {
      updateOrderUnifiedProductData(input: $input) {
        unifiedProduct {
          id
        }
      }
    }
  `
)