import moment from 'moment';
import React, {useState} from 'react';
import {graphql, QueryRenderer} from 'react-relay';
import {Link, useParams} from 'react-router-dom';
import {Col, ListGroup, ListGroupItem, Nav, Row, Table} from "reactstrap";
import environment from "../../../../Environment";
import UpdateOrderUnifiedProductData from "../../../../mutations/UpdateOrderUnifiedProductData";
import Button from '../../../../ui-kit/src/atoms/Button';
import GrayModal from '../../../../ui-kit/src/dataDisplay/GrayModal';
import {ErrorAlert} from "../../../../ui-kit/src/commons/errors"
import {getOnixUrl} from '../../../../ui-kit/src/commons/http';
import {ourToast} from '../../../../ui-kit/src/atoms/Toast';
import SourcesModal from "./unifiedDetailedViews/SourcesModal";
import ImagesModal from "./unifiedDetailedViews/ImagesModal";
import {sendProductToDataStore} from "../../../../common/serviceGateway/ProductStores";

const query = graphql`
    query ProductUnifiedProductsViewQuery($id: ID!) {
        product(id: $id) {
            id
            styleName
            styleNumber
            variants {
                edges {
                    node {
                        hexCode
                        variantName
                        variantCode
                        subvariants {
                            edges {
                                node {
                                    id
                                    name
                                    secondary
                                    gtin
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const unifiedQuery = graphql`
    query ProductUnifiedProductsViewSingleUnifiedQuery($id: ID!) {
        getUnifiedProduct(id: $id) {
            id
            compactData
            isShadow
            collection {
                id
                name
            }
            brand {
                id
                name
                systemId
            }
            createdDate
            stockQuantity
        }
    }
`;

function UnifiedProductDisplayedInfo({unifiedProduct}) {
  const [sourcesModalIsOpen, setSourcesModalIsOpen] = useState(false);
  const [imagesModalIsOpen, setImagesModalIsOpen] = useState(false);

  console.log({unifiedProduct})

  return <div>
    <Row>
      <Col className={'d-flex'}>
        <Button className={'mx-1'} onClick={() => setSourcesModalIsOpen(true)}>
          View sources
        </Button>
        <SourcesModal unifiedId={unifiedProduct.id}
                      isOpen={sourcesModalIsOpen}
                      toggle={() => setSourcesModalIsOpen(!sourcesModalIsOpen)}/>
        <Button className={'mx-1'} onClick={() => setImagesModalIsOpen(true)}>
          View images
        </Button>
        <ImagesModal unifiedId={unifiedProduct.id}
                     isOpen={imagesModalIsOpen}
                     toggle={() => setImagesModalIsOpen(!imagesModalIsOpen)}/>

        <Button onClick={() => {
          sendProductToDataStore({
                subvariantId: unifiedProduct.id,
                onResponse: (resp) => ourToast('success', 'Submitted!'),
                onError: (err) => ourToast('error', err.detail)
              }
          )
        }}>
          Send to Product Stores
        </Button>
      </Col>
    </Row>
    <Table>
      <tbody>
      <tr>
        <td>ID</td>
        <td>
          {unifiedProduct.id}
          <br/>
          <Button onClick={() => {
            window.open(getOnixUrl(`/admin/products/unifiedproduct/${atob(unifiedProduct.id).split(':').pop()}/change/`))
          }}>
            View in Django admin
          </Button>
        </td>
      </tr>
      <tr>
        <td>Date created</td>
        <td>{unifiedProduct.createdDate && moment(unifiedProduct.createdDate).toString()}</td>
      </tr>
      <tr>
        <td>Stock quantity</td>
        <td>{unifiedProduct.stockQuantity}</td>
      </tr>
      <tr>
        <td>Is Shadow / Temporary</td>
        <td>
          {unifiedProduct.isShadow ? <i className="fas fa-check text-danger"/> :
              <i className="fas fa-times text-success"/>} (green is good)
        </td>
      </tr>
      <tr>
        <td>Collection</td>
        <td>
          <Link to={'/brands/' + unifiedProduct.brand.id + '/collection-view/' + unifiedProduct.collection.id}>
            {unifiedProduct.collection.name}
          </Link>
        </td>
      </tr>
      <tr>
        <td>Brand</td>
        <td>
          <Link to={'/brands/' + unifiedProduct.brand.id}>
            {unifiedProduct.brand.name}
          </Link>
        </td>
      </tr>
      </tbody>
    </Table>

    <hr/>

    <h5>Data</h5>
    <div style={{overflow: 'auto',}}>
      <pre style={{maxWidth: '100%'}}>{JSON.stringify(unifiedProduct.compactData, null, 4)}</pre>
    </div>

  </div>
}

function UnifiedProductDisplay({id}) {
  return <QueryRenderer
      query={unifiedQuery}
      environment={environment}
      variables={{id}}
      render={({error, props}) => {
        if (error) {
          return <ErrorAlert error={error}/>
        }
        if (props && !props.getUnifiedProduct) {
          return <ErrorAlert error={'Failed to fetch the unified product'}/>
        }
        if (props && props.getUnifiedProduct) {
          return <div>
            <UnifiedProductDisplayedInfo unifiedProduct={props.getUnifiedProduct}/>
          </div>
        }
        return 'Loading...';
      }}
  />
}

class VariantsExplorer {
  constructor(variants) {
    this._variants = variants.edges.map(edge => edge.node);
  }

  iterateVariants() {
    return this._variants
  }

  iterateSubvariants(variantName, variantCode) {
    for (let variant of this._variants) {
      if (variant.variantName === variantName && variant.variantCode === variantCode) {
        return variant.subvariants.edges.map(edge => edge.node);
      }
    }
    return []
  }
}


function UnifiedProductListView({variants, initialHighlight}) {
  const [selectedSubvariantId, setSelectedSubvariantId] = useState(null);

  let explorer = new VariantsExplorer(variants);

  return <div>
    <Row>
      <Col md={4}>
        <ListGroup>
          {explorer.iterateVariants().map(variant => {
            return <ListGroupItem key={variant.variantName + variant.variantCode} style={{border: 0}}>
              <div className="d-flex align-items-center mb-2">
                <div style={{
                  height: '1rem',
                  width: '1rem',
                  borderRadius: '50%',
                  backgroundColor: variant.hexCode,
                  marginRight: '1rem'
                }}/>
                {variant.variantName} - {variant.variantCode}
              </div>
              <ListGroup>
                {explorer.iterateSubvariants(variant.variantName, variant.variantCode).map(subvariant => {
                  let isActive = subvariant.id === selectedSubvariantId;
                  return <ListGroupItem key={subvariant.id}
                                        className={'d-flex align-content-between'}
                                        action={true}
                                        onClick={e => {
                                          setSelectedSubvariantId(subvariant.id)
                                        }}
                                        active={isActive}>
                    <div>
                      {subvariant.name} {subvariant.secondary ? "/" : ""} {subvariant.secondary}
                      <br/>
                      <small className={isActive ? 'text-white' : 'text-muted'}>{subvariant.gtin}</small>
                    </div>
                  </ListGroupItem>
                })}
              </ListGroup>
            </ListGroupItem>
          })}
        </ListGroup>

      </Col>
      <Col md={8}>
        {selectedSubvariantId && <UnifiedProductDisplay id={selectedSubvariantId}/>}
      </Col>
    </Row>
    <Nav tabs>
    </Nav>
  </div>
}


export default function ProductUnifiedProductView() {
  const {productId} = useParams();
  const highlight = new URLSearchParams(window.location.search).get('highlight');

  return <QueryRenderer
      query={query}
      environment={environment}
      variables={{
        id: btoa('Product:' + atob(productId).split(':')[1]),
      }}
      render={({props, error}) => {
        if (error) {
          return <ErrorAlert error={error}/>
        }
        if (props) {
          return <>
            <UnifiedProductListView variants={props.product.variants}
                                    initialHighlight={highlight}/>
          </>
        }
        return 'Loading...';
      }}
  >

  </QueryRenderer>
}