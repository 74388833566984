import React, {Component} from 'react';
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';

import {

  AppNavbarBrand,
  AppSidebarToggler
} from '@coreui/react';
import logo from '../../assets/img/brand/logo-1-white.svg'
import sygnet from '../../assets/img/brand/logo-1-icon-white.svg'
import {withRouter} from "react-router-dom";
import {deleteAuthToken} from "../../Environment";
import UserAvatarMenu from "../../ui-kit/src/specialized/navigation/UserAvatarMenu";
import {withUser} from "../../context/UserContext";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  render() {

    // eslint-disable-next-line
    const {children, user, ...attributes} = this.props;

    return (
        <React.Fragment>
          <AppSidebarToggler className="d-lg-none" display="md" mobile/>
          <AppNavbarBrand
              full={{src: logo, width: 96, height: 35, alt: 'Vuuh Logo'}}
              minimized={{src: sygnet, width: 30, height: 30, alt: 'Vuuh Logo'}}
          />
          <AppSidebarToggler className="d-md-down-none" display="lg"/>

          <Nav className="d-md-down-none" navbar>
            <NavItem className="px-3">
              <NavLink href="/">Dashboard</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink href="/users">Users</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink href="/tasks">Tasks</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink href="/system/task-queues">Processing queues</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink href="/system/dev-snapshots">Dev Snapshots</NavLink>
            </NavItem>
          </Nav>
          <div className={"ml-auto"}>
            <UserAvatarMenu
                name={user.firstName + ' ' + user.lastName}
                email={user.email}
                handleLogOut={() => {
                  deleteAuthToken();
                  this.props.history.push("/login");
                }}
                handleSettings={() => {
                }}
            />
          </div>
        </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default withRouter(withUser(DefaultHeader));
