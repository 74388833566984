/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateOrderUnifiedProductDataInput = {
    order: string;
    unifiedProduct: string;
    data?: unknown | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateOrderUnifiedProductDataMutationVariables = {
    input: UpdateOrderUnifiedProductDataInput;
};
export type UpdateOrderUnifiedProductDataMutationResponse = {
    readonly updateOrderUnifiedProductData: {
        readonly unifiedProduct: {
            readonly id: string;
        } | null;
    } | null;
};
export type UpdateOrderUnifiedProductDataMutation = {
    readonly response: UpdateOrderUnifiedProductDataMutationResponse;
    readonly variables: UpdateOrderUnifiedProductDataMutationVariables;
};



/*
mutation UpdateOrderUnifiedProductDataMutation(
  $input: UpdateOrderUnifiedProductDataInput!
) {
  updateOrderUnifiedProductData(input: $input) {
    unifiedProduct {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateOrderUnifiedProductDataPayload",
    "kind": "LinkedField",
    "name": "updateOrderUnifiedProductData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnifiedProductNode",
        "kind": "LinkedField",
        "name": "unifiedProduct",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOrderUnifiedProductDataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateOrderUnifiedProductDataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c3fae886d38172e4e49173347ef2c2d4",
    "id": null,
    "metadata": {},
    "name": "UpdateOrderUnifiedProductDataMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOrderUnifiedProductDataMutation(\n  $input: UpdateOrderUnifiedProductDataInput!\n) {\n  updateOrderUnifiedProductData(input: $input) {\n    unifiedProduct {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd0a778255178eb8e0fe9020bcd7cda74';
export default node;
