import React from "react";
import Button from './Button';

function PrimaryActionButton({loading, children, block, ...props}) {
  return <Button loading={loading} block={block} color={'primary'} {...props}>
    {children}
  </Button>
}

export default PrimaryActionButton;
