import React from "react";
import GrayModal, {GrayModalProps} from "../../dataDisplay/GrayModal";

export type ConfirmationModalProps = GrayModalProps & {
  onConfirm: () => void,
  onReject: () => void,
  message?: string,
  confirmEnabled?: boolean,
  confirmText?: string
}

export default function ConfirmationModal({
                                            message = 'Are you sure?',
                                            title = 'Confirm',
                                            onConfirm,
                                            onReject,
                                            isOpen,
                                            toggle,
                                            confirmEnabled = true,
                                            confirmText = 'Confirm',
                                            ...rest
                                          }: ConfirmationModalProps): JSX.Element {
  return <GrayModal
    isOpen={isOpen}
    toggle={toggle}
    title={title || 'Warning!'}
    bodyContent={
      <div style={{padding: '1.5rem 3rem'}}>
        <p>{message}</p>
      </div>
    }
    primaryHeaderButton={{
      text: confirmText,
      isDisabled: !confirmEnabled,
      onClick: onConfirm,
      dataTestId: 'primary-header-button'
    }}
    secondaryHeaderButton={{text: "Cancel", onClick: onReject}}
    {...rest}
  />
}
