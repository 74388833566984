/**
 * Usage: ourToast('success / error / warning / info / neutral', 'Title', 'Message');
 **/

import React from 'react';
import styles from './Toast.module.scss';
import addClassNames from "../classNameUtils";
import {capitalize} from "../stringUtils";
// eslint-disable-next-line no-restricted-imports
import {toast} from "react-toastify";

type ToastTypeOptions = 'success' | 'warning' | 'info' | 'error' | 'neutral';

type ToastProps = {
  type: ToastTypeOptions,
  title: string | Error,
  message?: string | Error,
  closeToast?: () => void
}

const getString = (value: string | Error, type: ToastTypeOptions) => {
  if(typeof value === 'string') {
    return value;
  } else if(value instanceof Error && value?.message) {
    return value.message;
  }
  return capitalize(type);
}

export function Toast({type = 'success', title, message, closeToast}: ToastProps) {
  return <div className={'d-flex w-100 align-items-center'} style={{zIndex: 9999}}>
    <div className={addClassNames([
      {className: styles.notificationLine, condition: true},
      {className: styles.successLine, condition: type === 'success'},
      {className: styles.warningLine, condition: type === 'warning'},
      {className: styles.infoLine, condition: type === 'info'},
      {className: styles.errorLine, condition: type === 'error'},
      {className: styles.neutralLine, condition: type === 'neutral'},
    ])}/>
    <div className={'d-flex flex-column justify-content-center mt-1'}>
      <p className={styles.title}>{getString(title, type)}</p>
      {message && <p className={styles.message}>{getString(message, type)}</p>}
    </div>
    <i className={'fa-regular fa-xmark align-self-center ml-auto pl-3 mr-1'} onClick={() => closeToast && closeToast()}/>
  </div>
}

export function ourToast(type: ToastTypeOptions = 'success', title: string | Error, message?: string | Error) {
  return toast(<Toast type={type} title={title} message={message} />);
}
