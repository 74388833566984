/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteProductInput = {
    id: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteProductMutationVariables = {
    input: DeleteProductInput;
};
export type DeleteProductMutationResponse = {
    readonly deleteProduct: {
        readonly success: boolean | null;
        readonly instanceProductNode: {
            readonly id: string;
        } | null;
        readonly instanceDisplayProductNode: {
            readonly id: string;
        } | null;
    } | null;
};
export type DeleteProductMutation = {
    readonly response: DeleteProductMutationResponse;
    readonly variables: DeleteProductMutationVariables;
};



/*
mutation DeleteProductMutation(
  $input: DeleteProductInput!
) {
  deleteProduct(input: $input) {
    success
    instanceProductNode {
      id
    }
    instanceDisplayProductNode {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteProductPayload",
    "kind": "LinkedField",
    "name": "deleteProduct",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductNode",
        "kind": "LinkedField",
        "name": "instanceProductNode",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DisplayProductNode",
        "kind": "LinkedField",
        "name": "instanceDisplayProductNode",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteProductMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteProductMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e889ef9a903d66c34ec3788ea8a27c57",
    "id": null,
    "metadata": {},
    "name": "DeleteProductMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteProductMutation(\n  $input: DeleteProductInput!\n) {\n  deleteProduct(input: $input) {\n    success\n    instanceProductNode {\n      id\n    }\n    instanceDisplayProductNode {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'df4620b9bffb5a83061e207abd4a7fc5';
export default node;
