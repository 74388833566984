import React from "react";
import {DropdownItem, Row, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown} from "reactstrap";

function UserAvatarMenu({name, email, displaySettings, hasManageUsersPermission, handleSettings, handleLogOut, onUserManagementClick}) {
  return <Nav className="mr-3" navbar>
    <UncontrolledDropdown nav direction="down">
      <DropdownToggle nav>
        <Row className={"mr-3 my-3"}>
          <p className={"font-weight-bold mb-0"}>{(!!name.replace(/\s/g, '').length) ? name : email}</p>
          <i className={"fas fa-sort-down ml-1"}/>
        </Row>
      </DropdownToggle>
      <DropdownMenu direction="left" className={"overflow-hidden"}>
        {displaySettings && <DropdownItem onClick={handleSettings}>
          <i className="fas fa-cogs"/> Settings
        </DropdownItem>}
        {hasManageUsersPermission && <DropdownItem onClick={onUserManagementClick}>
          <i className="fas fa-user-friends"/> User Management
        </DropdownItem>}
        <DropdownItem onClick={handleLogOut}>
          <i className="fa fa-lock"/>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  </Nav>
}

export default UserAvatarMenu;
