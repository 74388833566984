export type conditionalClassName = {
  className: string | undefined
  condition: boolean | undefined
}

export default function addClassNames(conditionalClassNames: conditionalClassName[]) {
  let result = '';
  for (const conditionalClassName of conditionalClassNames) {
    if (conditionalClassName.condition && conditionalClassName.className) {
      result = result + conditionalClassName.className + ' ';
    }
  }
  return result.trim();
}

export function getClassNames(conditionalClassNames: conditionalClassName[]) {
  return addClassNames(conditionalClassNames)
}
