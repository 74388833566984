import React, {isValidElement, useState} from "react";
import {Link} from "react-router-dom";
import styles from './Button.module.scss'
import addClassNames, {getClassNames} from "../classNameUtils";

export interface ButtonProps extends React.ComponentPropsWithRef<"button"> {
  color?: string
  disabled?: boolean
  icon?: string | JSX.Element
  onRemove?: () => void;
  href?: string
}

export default function Button(props: ButtonProps): JSX.Element {
  const {children, color = 'secondary', disabled, className, icon, onRemove, href, ...rest} = props;
  const [displayRemoveButton, setDisplayRemoveButton] = useState(false);

  const classNames = [
    {className: styles.button, condition: true},
    {className: styles[color], condition: true},
    {className: styles.disabled, condition: !!disabled},
    {className: className, condition: className !== undefined}
  ]

  const getIconComponent = () => {
    if (isValidElement(icon)) {
      return icon;
    } else if (typeof icon === "string") {
      // include a margin right by default, only when there are children. If there are no children,
      // and just the icon, remove the margin with mr-0
      return <i className={`fas ${icon} ${!children ? 'mr-0' : ''}`}/>
    }
    return null
  }

  let finalClassNames = classNames;
  if (href && !disabled) {
    finalClassNames = [...finalClassNames, {className: styles.anchor, condition: true}]
    if (href.startsWith('http')) {
      // external
      return <a href={href} style={{textDecoration: 'none', color: 'white'}} className={addClassNames(finalClassNames)}>
        {getIconComponent()}
        {children}
      </a>
    }
    return <Link to={href} style={{textDecoration: 'none', color: 'white'}} className={addClassNames(finalClassNames)}>
      {children}
    </Link>
  }

  return <button
    onMouseEnter={() => !!onRemove && setDisplayRemoveButton(true)}
    onMouseLeave={() => !!onRemove && setDisplayRemoveButton(false)}
    disabled={disabled}
    className={getClassNames(finalClassNames)}
    {...rest}>
    {getIconComponent()}
    {displayRemoveButton && onRemove && <i className={"fas fa-minus " + styles.removeIcon} onClick={e => {
      e.stopPropagation();
      !!onRemove && onRemove();
    }}/>}
    {children}
  </button>
}
