import React from 'react';
import Environment, {deleteAuthToken} from "../../Environment";
import {QueryRenderer, graphql} from "react-relay";
import {Redirect} from "react-router-dom";
import UserContext from "../../context/UserContext";
import Page500 from "../../ui-kit/src/pages/Page500";
import MyLoaderOverlay from "../../components/LoadingOverlay";
import {
  LoginRequiredContainerQuery,
  LoginRequiredContainerQueryResponse
} from "./__generated__/LoginRequiredContainerQuery.graphql";

const query = graphql`
    query LoginRequiredContainerQuery {
        currentUser {
            isSystemAdmin
            id
            firstName
            lastName
            email
            brand {
                id
                name
                logo
                pendingDataSubscriptionRequests
                hasPermissions {
                    changeBrand
                }
            }
        }
    }
`

const getCurrentUser = (currentUser: LoginRequiredContainerQueryResponse['currentUser']) => {
  return {
    id: currentUser?.id || "",
    email: currentUser?.email || "",
    firstName: currentUser?.firstName || "",
    lastName: currentUser?.lastName || "",
    isSystemAdmin: currentUser?.isSystemAdmin || false,
    brand: {
      id: currentUser?.brand?.id || "",
      name: currentUser?.brand?.name || "",
      logo: currentUser?.brand?.logo || null,
      hasPermissions: {
        changeBrand: currentUser?.brand?.hasPermissions?.changeBrand || false
      },
      pendingDataSubscriptionRequests: currentUser?.brand?.pendingDataSubscriptionRequests || 0
    },
    isAdminApp: true
  }
}

function LoginRequiredContainer({children}: {children: JSX.Element | JSX.Element[]}) {
  return <QueryRenderer<LoginRequiredContainerQuery>
    environment={Environment}
    query={query}
    variables={{}}
    render={({props, error}) => {
      if (!error && !props) {
        return <MyLoaderOverlay active={true} children={undefined}/>
      } else if (error) {
        return <Page500/>
      } else if (props && props.currentUser) {
        if (!props.currentUser.isSystemAdmin) {
          return <Redirect to={"/forbidden"}/>
        }
        return <UserContext.Provider value={getCurrentUser(props.currentUser)}>
          {children}
        </UserContext.Provider>;
      } else if (props && !props.currentUser) {
        //console.log(window.location);
        deleteAuthToken();
        return <Redirect to={"/login?next=" + window.location.href}/>
      }
      return <div>:)</div>
    }}
  />
}

export default LoginRequiredContainer;
