import React, {useState} from 'react';
import Card, {CardBody, CardTitle, SimpleCardHeader} from '../../ui-kit/src/atoms/Card';
import Button from '../../ui-kit/src/atoms/Button';
import environment from "../../Environment";
import {graphql, QueryRenderer} from "react-relay";
import {GoogleOAuthConfigViewQuery} from './__generated__/GoogleOAuthConfigViewQuery.graphql';
import {useCurrentUser} from "../../context/UserContext";
import Input from '../../ui-kit/src/atoms/Input';
import {createMutationBoilerplate} from "../../ui-kit/src/commons/graphql";
import {ourToast} from "../../ui-kit/src/atoms/Toast";
import { GoogleOAuthConfigViewTestConnectionMutation } from './__generated__/GoogleOAuthConfigViewTestConnectionMutation.graphql';


const TestDriveConnectionMutation = createMutationBoilerplate<GoogleOAuthConfigViewTestConnectionMutation>(graphql`
    mutation GoogleOAuthConfigViewTestConnectionMutation($input: TestGoogleDriveConnectionMutationInput!) {
        testGoogleDriveConnection(input: $input) {
            valid
        }
    }
`)


function TestConnection() {
  const [folderId, setFolderId] = useState('');

  return <div className={'d-flex'} style={{width: '30rem'}}>
    <Input value={folderId}
           onChange={e => setFolderId(e.target.value)}
           placeholder={'Folder ID'}
           width={'20rem'}
           className={'mr-2'}/>
    <Button onClick={e => {
      TestDriveConnectionMutation(
          environment,
          {folderId},
          resp => {
            ourToast('success', 'Completed! Check the target folder')
          },
          err => {
            ourToast('error', `Failed: ${JSON.stringify(err)}`)
          }
      )
    }}
    >
      Create test file!
    </Button>
  </div>
}


export default function GoogleOAuthConfigView() {
  return <Card className={'mt-3'}>
    <SimpleCardHeader>
      <CardTitle>Google Drive configuration</CardTitle>
    </SimpleCardHeader>
    <CardBody>
      <p>This connection is used to manage the edi@vuuh.com automated connection</p>
      <p>When delivering files through Google Drive, we do it with this user</p>
      <QueryRenderer<GoogleOAuthConfigViewQuery>
          environment={environment}
          variables={{email: 'edi@vuuh.com'}}
          query={graphql`
          query GoogleOAuthConfigViewQuery($email: String!) {
            googleOauthUrl
            isGoogleOauthConnected(email: $email)
          }
        `}
          render={({error, props}) => {
            if (error) {
              return <p className="text-danger">{JSON.stringify(error)}</p>
            }
            if (props) {
              if (props.isGoogleOauthConnected) {
                return <>
                  <p>Connected!</p>
                  <TestConnection/>
                </>
              }
              return <a href={props.googleOauthUrl}>Connect</a>
            }
            return 'Loading...'
          }}
      />
    </CardBody>
  </Card>
}