/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LoginRequiredContainerQueryVariables = {};
export type LoginRequiredContainerQueryResponse = {
    readonly currentUser: {
        readonly isSystemAdmin: boolean | null;
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
        readonly brand: {
            readonly id: string;
            readonly name: string;
            readonly logo: string | null;
            readonly pendingDataSubscriptionRequests: number | null;
            readonly hasPermissions: {
                readonly changeBrand: boolean | null;
            } | null;
        } | null;
    } | null;
};
export type LoginRequiredContainerQuery = {
    readonly response: LoginRequiredContainerQueryResponse;
    readonly variables: LoginRequiredContainerQueryVariables;
};



/*
query LoginRequiredContainerQuery {
  currentUser {
    isSystemAdmin
    id
    firstName
    lastName
    email
    brand {
      id
      name
      logo
      pendingDataSubscriptionRequests
      hasPermissions {
        changeBrand
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSystemAdmin",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandNode",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingDataSubscriptionRequests",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandPermissions",
            "kind": "LinkedField",
            "name": "hasPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changeBrand",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginRequiredContainerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginRequiredContainerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d873b8bc5efffea7c577cc80141de5de",
    "id": null,
    "metadata": {},
    "name": "LoginRequiredContainerQuery",
    "operationKind": "query",
    "text": "query LoginRequiredContainerQuery {\n  currentUser {\n    isSystemAdmin\n    id\n    firstName\n    lastName\n    email\n    brand {\n      id\n      name\n      logo\n      pendingDataSubscriptionRequests\n      hasPermissions {\n        changeBrand\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cdc0e9a07436efdfc9b0bbd3fbbf9ee7';
export default node;
