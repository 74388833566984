import {commitMutation, graphql} from "react-relay";
import Environment from "../Environment";


function LoginMutation(email, password, onSuccess, onError) {
  const vars = {
    email: email,
    password: password
  };

  commitMutation(
    Environment,
    {
      mutation: graphql`
        mutation LoginMutation($email: String!, $password: String!) {
          tokenAuth(email: $email, password: $password, scope: "admin") {
            token
          }
        }
      `,
      variables: vars,
      onCompleted: (response, errors) => {
        if (errors) {
          onError(errors);
        } else if (response && !errors) {
          onSuccess(response);
        }
      },
      onError: (error) => {
        onError(error)
      }
    }
  )
}

export default LoginMutation;
