import React from 'react';
import GrayModal from '../../../../../ui-kit/src/dataDisplay/GrayModal';
import {graphql, QueryRenderer} from "react-relay";
import environment from "../../../../../Environment";
import {ErrorAlert} from '../../../../../ui-kit/src/commons/errors';
import Loading from '../../../../../ui-kit/src/atoms/Loading';
import {SourcesModalQuery} from './__generated__/SourcesModalQuery.graphql';
import {Table} from 'reactstrap';
import TableHeader from '../../../../../ui-kit/src/general/table/TableHeader';
import {Link} from 'react-router-dom';
import TriggerReason from "../../../../IngestionChannels/components/TriggerReason";


const query = graphql`
    query SourcesModalQuery($unifiedId: ID!) {
        getUnifiedProduct(id: $unifiedId) {
            id
            rawProducts {
                edges {
                    node {
                        id
                        createdDate
                        sourceIngestionRun {
                            id
                            triggerReason {
                                id
                                ...TriggerReason_reason
                            }
                            channel {
                                id
                                name
                            }
                        }
                        sourceImportRun {
                            id
                            started
                            endTime
                        }
                    }
                }
            }
        }
    }
`

type SourcesModalProps = {
  unifiedId: string,
  isOpen: boolean,
  toggle: () => void
}

export default function SourcesModal({unifiedId, isOpen, toggle}: SourcesModalProps) {
  return <GrayModal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      title={'Unified sources'}
      secondaryHeaderButton={{
        text: 'Close',
        onClick: toggle
      }}
      bodyContent={<div className={'p-3'}>
        <QueryRenderer<SourcesModalQuery>
            environment={environment}
            query={query}
            render={({error, props}) => {
              if (error) {
                return <ErrorAlert error={error}/>
              }
              if (props) {
                return <Table>
                  <thead>
                  <th>Created</th>
                  <th>Source</th>
                  </thead>
                  <tbody>
                  {props.getUnifiedProduct?.rawProducts?.edges.map(edge => {
                    if (!edge?.node) {
                      return null;
                    }
                    let n = edge.node;
                    return <tr key={n.id}>
                      <td>
                        {(n.createdDate as string)}
                      </td>
                      <td>
                        {n.sourceImportRun && <div>
                          <p>Import run (from file)</p>
                        </div>}
                        {n.sourceIngestionRun && <div>
                          <p>
                            <Link to={'/system/ingestion-run/' + n.sourceIngestionRun.id}>
                              Ingestion run (automatic)
                            </Link>
                          </p>
                          <p>
                            <Link to={'/system/ingestion-channels/' + n.sourceIngestionRun.channel.id}>
                              {n.sourceIngestionRun.channel.name}
                            </Link>
                          </p>
                          <p>{n.sourceIngestionRun.triggerReason?.id ?
                              <TriggerReason reason={n.sourceIngestionRun.triggerReason}/> : 'Unknown trigger'}</p>
                        </div>}
                      </td>
                    </tr>
                  })}
                  </tbody>
                </Table>
              }
              return <Loading/>
            }}
            variables={{unifiedId: unifiedId}}
        />
      </div>}
  />
}