import {authTokenKey} from "../../Consts";
import {getOnixUrl} from "../../ui-kit/src/commons/http";
import {APIError} from "../APIServiceGatewayRenderer";


export type ProductStoreType = {
  id: string,
  name: string,
  brand_id: string,
  created: string,
  product_count: number,
  variant_count: number,
  subvariant_count: number,
  collection_count: number,
  image_count: number,
}

export type ProductType = {
  id: string,
  style_name: string,
  style_number: string,
  category: string | null,
  subcategory: string | null,
  country_of_origin: string | null,
  customs_tariff: string | null,
  data: object,
  collections: ReadonlyArray<CollectionType>
}

export type VariantType = {
  id: string,
  name: string | null,
  code: string | null,
  detail: string | null,
  hexcode: string | null,
  pantone: string | null,
  data: object
}

export type SubvariantType = {
  id: string,
  name: string | null,
  secondary: string | null,
  gtin: string,
  data: object
}

export type PriceType = {
  id: string
  country: string
  type: string
  amount: number
  currency: string
}

export type CollectionType = {
  id: string,
  name: string
}


export function createProductStore({brandId, brandName, onResponse, onError}: {
  brandId: string,
  brandName: string,
  onResponse: (resp: ProductStoreType) => void,
  onError: (resp: APIError) => void
}) {
  let authToken = localStorage.getItem(authTokenKey);
  let url = getOnixUrl('/gateway/product-stores/CreateProductStore');
  url && fetch(url, {
    method: 'post',
    body: JSON.stringify({brand_id: brandId, brand_name: brandName}),
    headers: {
      Authorization: `JWT ${authToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    if (resp.status === 200) {
      resp.json().then(onResponse);
    } else {
      resp.json().then(onError);
    }
  })
}


export function fetchProductStore({brandId, onResponse, onError}: {
  brandId: string,
  onResponse: (resp: ProductStoreType) => void,
  onError: (resp: APIError) => void
}) {
  let authToken = localStorage.getItem(authTokenKey);
  let url = getOnixUrl('/gateway/product-stores/GetProductStore');
  url && fetch(url, {
    method: 'post',
    body: JSON.stringify({brand: brandId}),
    headers: {
      Authorization: `JWT ${authToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    if (resp.status === 200) {
      resp.json().then(resp => onResponse(resp.response));
    } else {
      resp.json().then(onError);
    }
  })
}


export function listProductStores({onResponse, onError}: {
  onResponse: (resp: { items: ProductStoreType[] }) => void,
  onError: (error: APIError) => void
}) {
  let authToken = localStorage.getItem(authTokenKey);
  let url = getOnixUrl('/gateway/product-stores/ListProductStores');
  url && fetch(url, {
    method: 'post',
    body: '{}',
    headers: {
      Authorization: `JWT ${authToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    if (resp.status === 200) {
      resp.json().then(resp => onResponse(resp.response));
    } else {
      resp.json().then(onError);
    }
  })
}


export function sendProductToDataStore({subvariantId, onResponse, onError}: {
  subvariantId: string,
  onResponse: (resp: ProductStoreType) => void,
  onError: (resp: APIError) => void
}) {
  let authToken = localStorage.getItem(authTokenKey);
  let url = getOnixUrl('/gateway/product-stores/SendProductCompatibilityMode');
  url && fetch(url, {
    method: 'post',
    body: JSON.stringify({subvariant_id: subvariantId}),
    headers: {
      Authorization: `JWT ${authToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    if (resp.status === 200) {
      resp.json().then(onResponse);
    } else {
      resp.json().then(onError);
    }
  })
}


export function listVariants({storeId, productId, onResponse, onError}: {
  storeId: string,
  productId: string,
  onResponse: (resp: VariantType[]) => void,
  onError: (resp: APIError) => void
}) {
  let authToken = localStorage.getItem(authTokenKey);
  let url = getOnixUrl('/gateway/product-stores/ListVariants');
  url && fetch(url, {
    method: 'post',
    body: JSON.stringify({store_id: storeId, product_id: productId}),
    headers: {
      Authorization: `JWT ${authToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    if (resp.status === 200) {
      resp.json().then(resp => onResponse(resp.response.items));
    } else {
      resp.json().then(onError);
    }
  })
}


export function listSubvariantsForVariant({storeId, variantId, onResponse, onError}: {
  storeId: string,
  variantId: string,
  onResponse: (resp: SubvariantType[]) => void,
  onError: (resp: APIError) => void
}) {
  let authToken = localStorage.getItem(authTokenKey);
  let url = getOnixUrl('/gateway/product-stores/ListSubvariantsForVariant');
  url && fetch(url, {
    method: 'post',
    body: JSON.stringify({store_id: storeId, variant_id: variantId}),
    headers: {
      Authorization: `JWT ${authToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    if (resp.status === 200) {
      resp.json().then(resp => onResponse(resp.response.items));
    } else {
      resp.json().then(onError);
    }
  })
}

export function listPricesForSubvariant({storeId, subvariantId, onResponse, onError}: {
  storeId: string,
  subvariantId: string,
  onResponse: (resp: PriceType[]) => void,
  onError: (resp: APIError) => void
}) {
  let authToken = localStorage.getItem(authTokenKey);
  let url = getOnixUrl('/gateway/product-stores/ListPricesForSubvariant');
  url && fetch(url, {
    method: 'post',
    body: JSON.stringify({store_id: storeId, subvariant_id: subvariantId}),
    headers: {
      Authorization: `JWT ${authToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(resp => {
    if (resp.status === 200) {
      resp.json().then(resp => onResponse(resp.response.items));
    } else {
      resp.json().then(onError);
    }
  })
}
