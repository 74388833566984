import React, {useEffect, useState} from "react";
import styles from "./GrayModal.module.scss";
import {Modal, ModalBody, Row, Col} from "reactstrap";
import {noop} from "../commons/misc";
import Button from "../atoms/Button";
import addClassNames from "../classNameUtils";
import CircularProgressIndicator from "../atoms/CircularProgressIndicator";

type Step = {
  stepText: string | JSX.Element,
  hasAnimation?: boolean,
}

export type ButtonSpec = {
  text: string,
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  isDisabled?: boolean,
  dataTestId?: string
}

type RoadmapProps = {
  title?: string | JSX.Element,
  steps: Step[],
  index: number
}

export function Roadmap({title, steps, index}: RoadmapProps) {
  const { stepText, hasAnimation = true } = steps[index];
  // Forces CircularProgressIndicator to show a transition animation from previous index to the current one
  const [progressIndex, setProgressIndex] = useState(hasAnimation && index > 0 ? (index - 1) : index);

  useEffect(() => {
    if (hasAnimation) {
      const timeout = setTimeout(() => {
        setProgressIndex(index)
      }, 50);

      return () => clearTimeout(timeout);
    } else {
      setProgressIndex(index);
    }
  }, [index, hasAnimation]);

  return <div className={'d-flex align-items-center'}>
    <CircularProgressIndicator progress={100 / steps.length * progressIndex}
                               className={hasAnimation && styles.progressAnimation}
                               dimensions={{size: 50, bgRingWidth: 7}}
                               color={"purple"}>
      <p className={styles.stepIndicator}>{index + 1}/{steps.length}</p>
    </CircularProgressIndicator>
    <div className={styles.roadmapTextContainer}>
      {title && typeof title === "string" &&
        <p className={styles.stepTitle}>{title}</p>}
      {title && typeof title !== "string" && title}

      {(typeof stepText === "string") &&
        <p className={styles.stepText}>{stepText}</p>}
      {(typeof stepText !== "string") && stepText}
    </div>
  </div>
}

function getHeaderBgClass(headerBg?: string) {
  let headerClass = '';
  if (headerBg) {
    headerClass = 'bg-' + headerBg;
  }
  return headerClass;
}

export type GrayModalProps = {
  isOpen: boolean,
  toggle: () => void,
  title?: string | JSX.Element,
  headerContent?: JSX.Element,
  bodyContent?: JSX.Element | (() => JSX.Element),
  steps?: Step[],
  stepsIndex?: number,
  className?: string,
  primaryHeaderButton?: ButtonSpec,
  secondaryHeaderButton?: ButtonSpec,
  dangerHeaderButton?: ButtonSpec,
  customButtons?: JSX.Element,
  style?: object,
  size?: string,
  headerBg?: string,
  backdrop?: boolean | "static"
}

export default function GrayModal({
                                    isOpen,
                                    toggle,
                                    title,
                                    headerContent,
                                    bodyContent,
                                    steps,
                                    stepsIndex,
                                    className,
                                    primaryHeaderButton,
                                    secondaryHeaderButton,
                                    dangerHeaderButton,
                                    customButtons,
                                    style = {},
                                    size = 'md',
                                    headerBg,
                                    backdrop = true
                                  }: GrayModalProps) {

  if (typeof bodyContent == 'function') {
    bodyContent = bodyContent()
  }

  let hasHeaderArea = !!(title || headerContent || steps || primaryHeaderButton || secondaryHeaderButton || dangerHeaderButton || customButtons)

  let primaryButtonClasses = addClassNames([
    {className: 'ml-3', condition: !!secondaryHeaderButton},
    {className: 'ml-auto', condition: !secondaryHeaderButton},
  ])

  let modalBodyClasses = addClassNames([
    {className: "p-0", condition: true},
    {className: styles.bodyBackground, condition: hasHeaderArea},
    {className: styles.bodyOnlyBackground, condition: !hasHeaderArea}
  ])

  return <Modal className={styles.container + " " + (className ? className : '')}
                contentClassName={"gray-modal"}
                isOpen={isOpen}
                size={size}
                style={style}
                backdrop={backdrop || 'static'}
                toggle={toggle || noop}>
    {headerContent ||
      (hasHeaderArea &&
        <Row style={{padding: '1.5rem 3rem'}} className={"mx-0 " + styles.header + ` ${getHeaderBgClass(headerBg)}`}>
          <div className={"d-flex flex-column"}>
            {!steps ? <>
              {title && typeof title === "string" &&
                <h2 className={styles.title}>{title}</h2>}
              {title && typeof title !== "string" && title}
            </> : <Roadmap title={title} steps={steps} index={stepsIndex || 0}/>}
          </div>
          <Col className={'d-flex px-0 mt-auto'} style={{height: "2rem"}}>
            {customButtons || <>
              {secondaryHeaderButton &&
                <Button
                  color={'secondary'}
                  disabled={secondaryHeaderButton.isDisabled}
                  onClick={secondaryHeaderButton.onClick}
                  data-testid={secondaryHeaderButton.dataTestId}
                  className={"black-text-button ml-auto"}>
                  {secondaryHeaderButton.text}
                </Button>}
              {primaryHeaderButton &&
                <Button
                  color={'primary'}
                  disabled={primaryHeaderButton.isDisabled}
                  onClick={primaryHeaderButton.onClick}
                  data-testid={primaryHeaderButton.dataTestId}
                  className={primaryButtonClasses}>
                  {primaryHeaderButton.text}
                </Button>}
              {dangerHeaderButton &&
                <Button
                  color={'danger'}
                  disabled={dangerHeaderButton.isDisabled}
                  onClick={dangerHeaderButton.onClick}
                  data-testid={dangerHeaderButton.dataTestId}
                  className={'ml-3'}>
                  {dangerHeaderButton.text}
                </Button>}
            </>}
          </Col>
        </Row>)}
    <ModalBody className={modalBodyClasses}>
      {bodyContent}
    </ModalBody>
  </Modal>
}
