import React, {ReactNode} from "react";
import {useHistory} from 'react-router-dom';
import addClassNames, {getClassNames} from "../classNameUtils";
import styles from "./Card.module.scss"
import TextHeader from "./TextHeader";

/**
 * Usage:
 *
 * <Card shadow>
 *   <ColoredCardHeader/>
 *
 *   <SimpleCardHeader>
 *     <CardTitle>My title</CardTitle>
 *     <CardHeaderActions>
 *       <Button>...</Button>
 *       <Button>...</Button>
 *       <Separator/>
 *       <Button>...</Button>
 *     </CardHeaderActions>
 *   </SimpleCardHeader>
 *
 *   <CardBody>
 *
 *   </CardBody>
 * </Card>
 */

interface CardProps extends React.ComponentProps<"div"> {
  shadow?: boolean,
  border?: boolean
  fillSpace?: boolean
}

export default React.forwardRef<HTMLDivElement, CardProps>(({
                                                              children,
                                                              shadow,
                                                              fillSpace,
                                                              border,
                                                              className,
                                                              ...rest
                                                            }, ref) => {
    const classNames = [
      {className: styles.card, condition: true},
      {className: styles.shadow, condition: shadow},
      {className: styles.fillSpace, condition: fillSpace},
      {className: styles.border, condition: border},
      {className: className, condition: className !== undefined},
    ];

    return <div className={addClassNames(classNames)} ref={ref} {...rest}>
      {children}
    </div>
  }
);

export type SimpleCardHeaderProps = React.ComponentPropsWithoutRef<"div"> & {}

export function SimpleCardHeader({children, className, ...rest}: SimpleCardHeaderProps) {
  let classNames = addClassNames([
    {className: styles.simpleHeader, condition: true},
    {className: className, condition: !!className},
  ])
  return <div className={classNames} {...rest}>
    {children}
  </div>
}

export type CardTitleProps = React.ComponentPropsWithoutRef<"div"> & {}

export function CardTitle({children}: CardTitleProps) {
  return <TextHeader className={'mb-0'}>{children}</TextHeader>
}

export type ColoredCardHeaderProps = React.ComponentPropsWithoutRef<"div"> & {
  color: 'primary' | 'secondary' | 'warning' | 'info' | 'danger' | 'muted' | 'dark';
  headerText: ReactNode;
  onHeaderTextClick?: () => void,
  dataTestId?: string
};

export function ColoredCardHeader({
                                    children,
                                    color = 'secondary',
                                    headerText,
                                    onHeaderTextClick,
                                    dataTestId,
                                    ...rest
                                  }: ColoredCardHeaderProps) {
  const history = useHistory();

  return <div className={`${styles.coloredHeaderBand} bg-${color}`} {...rest}>
      <span onClick={onHeaderTextClick || history.goBack} style={{cursor: "pointer"}} data-testid={dataTestId}>
        <i className="fas fa-chevron-left mr-2"/> {headerText}
      </span>
  </div>
}

export type CardHeaderActionsProps = React.ComponentPropsWithoutRef<"div"> & {};

export function CardHeaderActions({children, ...rest}: CardHeaderActionsProps) {
  return <div className={`ml-auto d-flex align-items-center ${styles.headerActions}`} {...rest}>
    {children}
  </div>
}

type SeparatorProps = {
  className?: string
}

export function Separator({className}: SeparatorProps) {
  const classes = getClassNames([
    {className: styles.separator, condition: true},
    {className, condition: !!className}
  ]);

  return <div className={classes}></div>
}

export type CardBodyProps = React.ComponentPropsWithoutRef<"div"> & {};

export function CardBody({children, className, ...rest}: CardBodyProps) {
  return <div className={`${styles.cardBody} ${className || ''}`} {...rest}>
    {children}
  </div>
}
