import React, {useEffect} from 'react';
import {createMutationBoilerplate} from "../../ui-kit/src/commons/graphql";
import {graphql} from "react-relay";
import environment from "../../Environment";
import {GoogleOAuthViewFinalizeMutation} from './__generated__/GoogleOAuthViewFinalizeMutation.graphql';
import {ourToast} from "../../ui-kit/src/atoms/Toast";

const mutation = graphql`
    mutation GoogleOAuthViewFinalizeMutation($input: FinalizeGoogleOauthMutationInput!) {
        finalizeGoogleOauth(input: $input) {
            valid
        }
    }
`

const FinalizeGoogleOauthMutation = createMutationBoilerplate<GoogleOAuthViewFinalizeMutation>(mutation);

export default function GoogleOAuthView() {

  useEffect(() => {
    FinalizeGoogleOauthMutation(
        environment,
        {callbackUrl: window.location.href},
        resp => {
          window.location.href = '/system/integrations/google'
        },
        err => {
          ourToast('error', 'Failed to connect to Google, please try again or ask the devs')
        }
    )
  }, [])

  return <div>
    Ok
  </div>
}