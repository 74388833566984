import React, {useEffect} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {Link} from "react-router-dom";

function Page500() {

  useEffect(() => {
    setTimeout(reloadPage, 5000);
  }, []);

  const reloadPage = () => {
    window.location.reload();
  }

  return <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="6">
              <span className="clearfix">
                <h1 className="float-left display-3 mr-4">500</h1>
                <h4 className="pt-3">Houston, we have a problem!</h4>
                <p className="text-muted float-left">The page you are looking for is temporarily unavailable.</p>
              </span>
          <Button block color={'primary'} onClick={reloadPage}>
            <i className="fas fa-sync"/>&nbsp;&nbsp;&nbsp;
            Retry
          </Button>
          <div className={'text-center'}>
            <Link to={'/'}>Or return to the dashboard</Link>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
}

export default Page500;
