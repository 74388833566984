import React, {useState} from 'react';
import LoginMutation from "../../../mutations/LoginMutation";
import {setAuthToken} from "../../../Environment";
import {useHistory} from "react-router-dom";
import LoginModal, {loginFields} from "../../../ui-kit/src/specialized/userForms/login/LoginModal";
import bluredNavbar from "../../../assets/svg/navigation.svg";
import styles from "./Login.module.scss"
import {normalizeError} from '../../../../src/ui-kit/src/commons/errors'


function errorsBackendToFrontend(errors, setBadFields) {
  const errorMessages = [
    {backend: 'users.invalid_email', frontend: '*The email address is not valid.', badFields: [loginFields.email]},
    {backend: 'general.authentication_required', frontend: '*The email or password is incorrect.', badFields: [loginFields.email, loginFields.password]}
  ]
  if(errors) {
    const errorMessage = errorMessages.find(message => message.backend === errors);
    if(errorMessage?.frontend) {
      setBadFields(errorMessage.badFields);
      return [errorMessage.frontend];
    } else {
      setBadFields([])
      return ['*There was an error on our side. Please try again!'];
    }
  } else {
    setBadFields([])
    return ['*There was an error on our side. Please try again!'];
  }
}

function Login() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [badFields, setBadFields] = useState([])
  const history = useHistory();

  const formSubmit = ({email, password}) => {
    let next = new URLSearchParams(window.location.search).get('next') || '/dashboard';
    setLoading(true);
    LoginMutation(
      email,
      password,
      (response) => {
        setLoading(false);
        setErrors(null);
        setAuthToken(response.tokenAuth.token);
        history.push(next);
      },
      (errors) => {
        setLoading(false)
        setErrors(errorsBackendToFrontend(normalizeError(errors), setBadFields));
      }
    )
  };

  return (
    <div className="app flex-row align-items-center">
      <img src={bluredNavbar} className={styles.bluredNavbar} alt={'Blurred Vuuh Navbar'}/>
      <LoginModal onSubmit={formSubmit}
                  forgotPasswordUrl="#"
                  error={errors}
                  loading={loading}
                  badFields={badFields}
      />
    </div>
  );
}

export default Login;
