import React from "react";
import styles from "./Loading.module.scss"

type LoadingProps = {
  className?: string
}

export default function Loading({className = ''}: LoadingProps): JSX.Element {
  return <svg id={styles.dots} className={className} width="30px" height="30px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" fill="none">
      <g id={styles.dots} fill="#A3A3A3">
        <circle id={styles.dot1} cx="25" cy="30" r="10"/>
        <circle id={styles.dot2} cx="60" cy="30" r="10"/>
        <circle id={styles.dot3} cx="95" cy="30" r="10"/>
      </g>
    </g>
  </svg>
}
