import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import PrimaryActionButton from "../../../ui-kit/src/general/PrimaryActionButton";

class Forbidden extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <h4 className="pt-3">Forbidden</h4>
                <p className="text-muted float-left">This account is not a System admin</p>
                <p className="text-muted float-left">
                  Please contact your administrator to grant you access or us if you think this is a mistake
                </p>
              </span>
              <PrimaryActionButton onClick={() => {
                this.props.history.push('/login');
              }}>Back to Login page</PrimaryActionButton>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Forbidden;
