import React, {useState} from 'react';

import Card, {CardBody, CardTitle, SimpleCardHeader} from '../../../ui-kit/src/atoms/Card';
import {withRouter} from "react-router-dom";
import ProductUnifiedProductView from "./tabs/ProductUnifiedProductsView";
import environment from "../../../Environment";
import Button from "../../../ui-kit/src/atoms/Button"
import DeleteProductMutation from "../../../mutations/DeleteProductMutation";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import ConfirmationModal from "../../../ui-kit/src/organisms/Modals/ConfirmationModal"
import {ourToast} from '../../../ui-kit/src/atoms/Toast';
import {Col, Row} from "reactstrap";


function EditProduct({match}) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const toggleConfirmationModal = () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen)
  }

  let history = useHistory()

  const deleteMutation = () => {
    setIsLoading(true)
    DeleteProductMutation(
      environment,
      {id: match.params.productId},
      () => {
        ourToast('success', 'Product Deleted');
        history.push('/product/catalog/')
      },
      (error) => {
        setIsLoading(false)
        ourToast('error', 'Could not delete product');
      }
    )
  }

  return <Card shadow className={'mt-3'}>
      <SimpleCardHeader>
        <CardTitle>Product</CardTitle>
      </SimpleCardHeader>
      <CardBody>
        <Row>
          <Col className={'d-flex'}>
            <Button
              color={"danger"}
              disabled={isLoading}
              onClick={toggleConfirmationModal}
            >
              {isLoading ?
                <>
                  <i className={"fas fa-spinner fa-spin"}/>
                  <span> Deleting...</span>
                </> :
                "Delete Product"}
            </Button>
            <ConfirmationModal
              isOpen={isConfirmationModalOpen}
              message={"This action is permanent and irreversible. Are you sure you want to proceed?"}
              onConfirm={deleteMutation}
              onReject={toggleConfirmationModal}
              toggle={toggleConfirmationModal}
            />
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col>
            <ProductUnifiedProductView/>
          </Col>
        </Row>
      </CardBody>
    </Card>
}

export default withRouter(EditProduct);
