import {createMutationBoilerplate} from "../ui-kit/src/commons/graphql";
import {graphql} from "react-relay";
import {DeleteProductMutation} from "./__generated__/DeleteProductMutation.graphql";

export default createMutationBoilerplate<DeleteProductMutation>(
  graphql`
    mutation DeleteProductMutation($input: DeleteProductInput!) {
      deleteProduct(input: $input) {
        success
        instanceProductNode {
          id
        }
        instanceDisplayProductNode {
          id
        }
      }
    }
  `
)