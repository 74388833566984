import {Environment, Network, RecordSource, Store} from "relay-runtime";
import {authTokenKey} from "./Consts";
import {getOnixUrl} from "./ui-kit/src/commons/http";

const store = new Store(new RecordSource());

function fetchQuery(operation,
                    variables,) {
  // get the auth token
  let authToken = localStorage.getItem(authTokenKey);
  // make sure we don't pass the authToken null, will throw a 401 error.
  if (authToken === null) {
    authToken = ""
  }

  return fetch(getOnixUrl("/graphql/"), {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      // Add authentication and other headers here
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': "JWT " + authToken
    },
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  }).then(response => {
    // return response.text()
    return response.json();
  }).then(jsonBody => {
    if (jsonBody.errors && jsonBody.errors.length > 0) {
      throw new Error(jsonBody.errors[0].message);
    }
    return jsonBody;
    // return JSON.parse(x)
  });
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);

const environment = new Environment({
  network,
  store,
});

export default environment;


export function setAuthToken(token) {
  localStorage.setItem(authTokenKey, token);
}

export function deleteAuthToken() {
  localStorage.removeItem(authTokenKey);
}
