import React from 'react';
import {Alert} from "reactstrap";
import styles from"./errors.module.scss"

export type MultiPartHTTPRequestErrorType = {
  message: {
    code: string,
    params: {
      message: string
    }
  }
}[];

export type BackendErrorList = {
  extensions: {
    code: string,
    params: {
      message: string
    }
  },
  locations: {
    column: number,
    line: number
  }[],
  message: string,
  path: string[]
}[];

// An error that occurs in the javascript, is caught and sent directly here for handling
type JsError = { message: string };
// A graphql error that is sent from the backend as result of some faulty backend operation/validation
type BackendErrorExtension = { code: string, params: any };
type BackendError = { message: string, extensions: BackendErrorExtension };
// type BackendErrorList = BackendError[];

export type PossibleError = JsError | BackendErrorList | JSX.Element | string | null;

function hasError(error: PossibleError): boolean {
  if (Array.isArray(error) && error.length === 0) {
    return false;
  }
  return !!error;
}


type ErrorBuildersType = {
  [key: string]: (err: BackendError) => string
}

const ERROR_BUILDERS: ErrorBuildersType = {
  'ddt.invalid_parameters': (err: BackendError): string => {
    return err.extensions.params.message;
  },
}


function getCode(error: BackendErrorList): string | null {
  return error[0].extensions.code;
}


export function normalizeError(error: PossibleError): string | null {
  if (error === null) {
    return null;
  }

  if (typeof error === "string") {
    return error
  }

  if ('message' in error) {
    return error.message;
  }

  if (Array.isArray(error) && error[0].hasOwnProperty('extensions')) {
    let code = getCode(error);
    if (code) {
      return (ERROR_BUILDERS[code] || (() => code))(error[0])
    }
  }

  if (Array.isArray(error)) {
    return error[0].message.toString();
  }

  return 'Unknown error';
}

type ErrorAlertProps = {
  error: PossibleError
  translator?: (error: string | null) => string | null
}

// TODO: Replace CustomizedErrorHandler after revamp

export function ErrorAlert({error, translator}: ErrorAlertProps): JSX.Element | null {
  if (!hasError(error)) {
    return null;
  }
  if (React.isValidElement(error)) {
    return <Alert color={'danger'}>
      {error}
    </Alert>
  }

  return <Alert color={'danger'}>
    {translator ? translator(normalizeError(error)) : normalizeError(error)}
  </Alert>
}

export function ModalErrorAlert({error, translator} : ErrorAlertProps): JSX.Element | null {
  if (!hasError(error)) {
    return null;
  }
  if (React.isValidElement(error)) {
    return <div className={styles.redColor}>
      <i className="fa-thin fa-circle-exclamation mr-1"/>
      {error}
    </div>
  }

  return <div className={styles.redColor}>
    <i className="fa-thin fa-circle-exclamation mr-1"/>
    {translator ? translator(normalizeError(error)) : normalizeError(error)}
  </div>
}
