import React from "react";
import styles from './Pill.module.scss'
import addClassNames from "../classNameUtils";

export interface PillProps extends React.ComponentPropsWithoutRef<"button"> {
  active?: boolean;
  disabled?: boolean
  color?: 'primary' | 'secondary' | 'ternary' | 'transparent' | 'secondaryTransparent' | 'dropdownSecondary';
  icon?: string
}

export default function Pill(props: PillProps): JSX.Element {
  const {children, disabled, active = false, color = 'secondary',  className, icon, ...rest} = props

  const classNames = [
    {className: styles.pill, condition: true},
    {className: styles[color], condition: true},
    {className: styles.disabled, condition: disabled},
    {className: styles.active, condition: active},
    {className: className, condition: !!className}
  ]
  return <button
    disabled={disabled}
    className={addClassNames(classNames)}
    {...rest}>
    {icon && <i className={`${icon} mr-1`}/>}
    {children}
  </button>
}
