import React, {ReactNode} from 'react';
import GrayModal from '../../../../../ui-kit/src/dataDisplay/GrayModal';
import {graphql, QueryRenderer} from "react-relay";
import environment from "../../../../../Environment";
import {ErrorAlert} from '../../../../../ui-kit/src/commons/errors';
import Loading from '../../../../../ui-kit/src/atoms/Loading';
import {Badge, Col, Row} from 'reactstrap';
import {ImagesModalQuery} from './__generated__/ImagesModalQuery.graphql';
import Pill from '../../../../../ui-kit/src/atoms/Pill';
import ProductImagePlaceholder from '../../../../../ui-kit/src/molecules/products/ProductImagePlaceholder';


const query = graphql`
    query ImagesModalQuery($unifiedId: ID!) {
        getUnifiedProduct(id: $unifiedId) {
            id
            imageGroups {
                edges {
                    node {
                        id
                        styleNumber
                        colorCode
                        colorName
                        orientation
                        images {
                            edges {
                                node {
                                    id
                                    url
                                    hiResUrl
                                    lastModified
                                    image
                                    thumbnailSmall
                                    thumbnailMedium
                                    sizeCategory
                                    type
                                    tags
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`


function ImageUrl({href, children}: { href: string | null, children: ReactNode }) {
  if (href) {
    return <small><a href={href} target={'_blank'}>{children}</a></small>
  } else {
    return <small className={'text-muted'}>{children}</small>
  }
}

type ImagesModalProps = {
  unifiedId: string,
  isOpen: boolean,
  toggle: () => void
}

export default function ImagesModal({unifiedId, isOpen, toggle}: ImagesModalProps) {
  return <GrayModal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      title={'Images'}
      secondaryHeaderButton={{
        text: 'Close',
        onClick: toggle
      }}
      bodyContent={<div className={'p-3'}>
        <QueryRenderer<ImagesModalQuery>
            environment={environment}
            query={query}
            render={({error, props}) => {
              if (error) {
                return <ErrorAlert error={error}/>
              }
              if (props) {
                return <div>
                  {props.getUnifiedProduct?.imageGroups.edges.map(edge => {
                    if (!edge?.node) {
                      return null
                    }
                    let n = edge.node;
                    return <div key={n.id}>
                      <div className="d-flex my-3">

                        <Pill className={'mx-1'}>Style number "{n.styleNumber}"</Pill>
                        <Pill className={'mx-1'}>Color name "{n.colorName}"</Pill>
                        <Pill className={'mx-1'}>Color code "{n.colorCode}"</Pill>
                        <Pill className={'mx-1'}>Orientation <Badge color={'primary'}>{n.orientation}</Badge></Pill>

                      </div>
                      <Row>
                        {n.images.edges.map(edge => {
                          if (!edge?.node) {
                            return null;
                          }
                          let img = edge.node;
                          return <Col md={6} key={img.id}>
                            <div className="p-3 rounded bg-white shadow-sm">

                              <Row>
                                <Col className="d-flex justify-content-center mb-3">
                                  {img.thumbnailMedium ?
                                      <img style={{height: '82px', width: '82px'}}
                                           className={'shadow-sm'}
                                           src={img.thumbnailMedium} alt=""/> :
                                      <ProductImagePlaceholder size={'mdLg'}/>}
                                </Col>
                              </Row>
                              <Row className={'d-flex'}>
                                <Col md={4} className={'d-flex flex-column'}>
                                  <ImageUrl href={img.hiResUrl}>Hi-res URL</ImageUrl>
                                  <ImageUrl href={img.url}>Image</ImageUrl>
                                  <ImageUrl href={img.thumbnailSmall}>Small thumbnail</ImageUrl>
                                  <ImageUrl href={img.thumbnailMedium}>Medium thumbnail</ImageUrl>
                                </Col>
                                <Col md={8} className={'d-flex flex-column'}>
                                  <small>Last modified: {img.lastModified}</small>
                                  <small>Size category: <Badge color={'secondary'}>{img.sizeCategory}</Badge></small>
                                  <small>Type: <Badge color={'primary'}>{img.type}</Badge></small>
                                </Col>
                                <Col md={12}>
                                  <p>Tags: <pre style={{fontSize: '12px'}}>
                                    {JSON.stringify(JSON.parse(img.tags as string), null, 2)}
                                  </pre>
                                  </p>

                                </Col>
                              </Row>
                            </div>

                          </Col>
                        })}
                      </Row>
                      <hr/>
                    </div>
                  })}
                </div>
              }
              return <Loading/>
            }}
            variables={{unifiedId: unifiedId}}
        />
      </div>}
  />
}