import React from 'react';
import styles from './CircularProgressIndicator.module.scss';
import addClassNames from "../classNameUtils";

/**
 * @param progress: null if progress is unknown
 */
type CircularProgressIndicatorProps = {
  progress: number | null,
  className?: string,
  color?: "complete" | "incomplete" | "purple" | "unknown",
  dimensions?: {
    size?: number,
    bgRingWidth?: number,
    progressRingSpacing?: number
  }
  children?: JSX.Element
}

function CircularProgressIndicator({progress, className, color, dimensions, children}: CircularProgressIndicatorProps) {
  const size = dimensions?.size || 48;
  const bgRingWidth = dimensions?.bgRingWidth || 6;
  const progressRingSpacing = dimensions?.progressRingSpacing || 1;
  const progressRingRadius = (size - bgRingWidth) / 2;
  const progressCircumference = 2 * Math.PI * progressRingRadius;
  const progressOffset = ((100 - (progress !== null ? progress : 100)) / 100) * progressCircumference;
  if(!color) {
    if(progress === null) {
      color = "unknown";
    } else if(progress === 100) {
      color = "complete";
    } else {
      color = "incomplete";
    }
  }

  const bgRingStyle = {
    width: size,
    height: size,
    minWidth: size,
    minHeight: size,
    borderWidth: bgRingWidth
  }

  const svgStyle = {
    top: -(bgRingWidth - progressRingSpacing),
    left: -(bgRingWidth - progressRingSpacing),
    width: size - progressRingSpacing * 2,
    height: size - progressRingSpacing * 2,
  }

  return <div style={bgRingStyle} className={addClassNames([
    {className: styles.bgRing, condition: true},
    {className: className, condition: !!className},
    {className: styles.bgRingComplete, condition: color === "complete"},
    {className: styles.bgRingIncomplete, condition: color === "incomplete"},
    {className: styles.bgRingPurple, condition: color === "purple"},
    {className: styles.bgRingUnknown, condition: color === "unknown"},
  ])}>
    <div className={styles.svgContainer}>
      <svg xmlns="http://www.w3.org/2000/svg"
           version="1.1"
           style={svgStyle}
           className={styles.svg}>
        <circle
          className={addClassNames([
            {className: styles.progressRing, condition: true},
            {className: styles.progressRingComplete, condition: color === "complete"},
            {className: styles.progressRingIncomplete, condition: color === "incomplete"},
            {className: styles.progressRingPurple, condition: color === "purple"},
            {className: styles.progressRingUnknown, condition: color === "unknown"},
          ])}
          cx={(size - progressRingSpacing * 2) / 2}
          cy={(size - progressRingSpacing * 2) / 2}
          r={progressRingRadius}
          strokeWidth={bgRingWidth - progressRingSpacing * 2}
          strokeDasharray={progressCircumference}
          strokeDashoffset={progressOffset}
        />
      </svg>
    </div>
    <div className={styles.childrenContainer}>
      {children || <div className={styles.progressText}>
        {progress !== null ? <p className={`mb-0`}>{progress}%</p> : <i className={`fa-solid fa-question ${styles.icon}`}/>}
      </div>}
    </div>
  </div>
}

export default CircularProgressIndicator;
