/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GoogleOAuthConfigViewQueryVariables = {
    email: string;
};
export type GoogleOAuthConfigViewQueryResponse = {
    readonly googleOauthUrl: string;
    readonly isGoogleOauthConnected: boolean;
};
export type GoogleOAuthConfigViewQuery = {
    readonly response: GoogleOAuthConfigViewQueryResponse;
    readonly variables: GoogleOAuthConfigViewQueryVariables;
};



/*
query GoogleOAuthConfigViewQuery(
  $email: String!
) {
  googleOauthUrl
  isGoogleOauthConnected(email: $email)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "googleOauthUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "kind": "ScalarField",
    "name": "isGoogleOauthConnected",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GoogleOAuthConfigViewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GoogleOAuthConfigViewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ab99acc802c667f6213956fdb2b490d2",
    "id": null,
    "metadata": {},
    "name": "GoogleOAuthConfigViewQuery",
    "operationKind": "query",
    "text": "query GoogleOAuthConfigViewQuery(\n  $email: String!\n) {\n  googleOauthUrl\n  isGoogleOauthConnected(email: $email)\n}\n"
  }
};
})();
(node as any).hash = '8e6dab89086438734a8990ddc354f762';
export default node;
