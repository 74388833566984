/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DataPullRunState = "FAILED" | "PENDING" | "RUNNING" | "SUCCESS" | "%future added value";
export type TriggerReason_reason = {
    readonly id: string;
    readonly __typename: string;
    readonly file?: string | null | undefined;
    readonly tags?: ReadonlyArray<string> | undefined;
    readonly timestamp?: unknown | undefined;
    readonly brand?: {
        readonly " $fragmentRefs": FragmentRefs<"SmallInlineBrandDisplay_brand">;
    } | null | undefined;
    readonly retailer?: {
        readonly " $fragmentRefs": FragmentRefs<"SmallInlineRetailerDisplay_retailer">;
    } | null | undefined;
    readonly state?: DataPullRunState | undefined;
    readonly finishedDate?: unknown | null | undefined;
    readonly dataPull?: {
        readonly id: string;
    } | null | undefined;
    readonly " $refType": "TriggerReason_reason";
};
export type TriggerReason_reason$data = TriggerReason_reason;
export type TriggerReason_reason$key = {
    readonly " $data"?: TriggerReason_reason$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TriggerReason_reason">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TriggerReason_reason",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "file",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandNode",
          "kind": "LinkedField",
          "name": "brand",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SmallInlineBrandDisplay_brand"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RetailerNode",
          "kind": "LinkedField",
          "name": "retailer",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SmallInlineRetailerDisplay_retailer"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "IngestedFileNode",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finishedDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DataPullNode",
          "kind": "LinkedField",
          "name": "dataPull",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "DataPullRunNode",
      "abstractKey": null
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
})();
(node as any).hash = '7fd195e339f8db8a8159d5e295a6a542';
export default node;
