import React from "react";
import styles from './ProductImagePlaceholder.module.scss';

type ProductImagePlaceholderProps = {
  size?: 'sm' | 'md' | 'mdLg' | 'lg' | 'xl'
  className?: string
}

function ProductImagePlaceholder({size = 'md', className = ''}: ProductImagePlaceholderProps): JSX.Element {
  return <div className={`product-thumbnail bg-muted mr-2 ${styles[size]} ${styles.productImagePlaceholder} ${className}`}>
    <div className={`${styles.productImagePlaceholder} ${styles[size]} d-flex align-items-center justify-content-center`}>
      <i className="fas fa-image fa-2x"/>
    </div>
  </div>
}

export default ProductImagePlaceholder;
