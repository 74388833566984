import {commitMutation} from "react-relay";


export function createMutationBoilerplate(graphqlCode) {
  return function (environment, parameters, onSuccess, onError, deleteId = null, isToBeDestructured = false, configs) {
    commitMutation(
      environment,
      {
        mutation: graphqlCode,
        variables: (isToBeDestructured ? parameters : {input: parameters}),
        onCompleted: (response, errors) => {
          if (errors) {
            onError(errors);
          } else if (response && !errors) {
            onSuccess(response);
          }
        },
        onError: (error) => {
          if (Array.isArray(error)) {
            onError(error)
          } else {
            onError([{message: error.toString()}])
          }
        },
        updater: (store) => {
          if (deleteId) {
            store.delete(deleteId)
          }
        },
        ...(configs && {configs: configs})
      },
    )
  }
}


export function createMutationCRUDBoilerplate(createGraphqlCode, updateGraphqlCode, deleteGraphqlCode) {
  return {
    create: createMutationBoilerplate(createGraphqlCode),
    update: createMutationBoilerplate(updateGraphqlCode),
    'delete': createMutationBoilerplate(deleteGraphqlCode)
  }
}


export function getGraphqlTypeFromId(value) {
  if (!value || typeof value !== 'string') {
    return null
  }
  try {
    let data = Buffer.from(value, 'base64');
    let split = data.toString().split(':')
    if (split.length !== 2) {
      return null;
    }
    return split[0];
  } catch (e) {
    return null;
  }
}

