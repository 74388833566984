import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import {ToastContainer} from "react-toastify";
import {Container} from 'reactstrap';
// sidebar nav config
import navigation from '../../_nav';
import routes from '../../routes';
import LoginRequiredContainer from "../LoginRequiredContainer";
import DefaultAside from './DefaultAside';
import DefaultHeader from './DefaultHeader';

class DefaultLayout extends Component {
  componentDidMount() {
    document.documentElement.style.setProperty('--sidebar-width', "250px");
  }

    render() {
    return (<LoginRequiredContainer>
        <div className="app">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
            closeButton={false}
          />
          <AppHeader fixed>
            <DefaultHeader/>
          </AppHeader>
          <div className="app-body">
            <AppSidebar fixed display="lg" className="pidgey-sidebar">
              <AppSidebarHeader/>
              <AppSidebarForm/>
              <AppSidebarNav navConfig={navigation} {...this.props} />
              <AppSidebarFooter/>
              <AppSidebarMinimizer/>
            </AppSidebar>
            <main className="main">
              {/*<AppBreadcrumb appRoutes={routes}/>*/}
              <Container fluid>
                <Switch>
                  {routes.map((route, idx) => {
                      return route.component ? (
                          <Route key={idx} path={route.path} exact={route.exact}
                                 name={route.name} render={props => (
                            <route.component {...props} />
                          )}/>)
                        : (null);
                    },
                  )}
                  <Redirect from="/" to="/dashboard"/>
                </Switch>
              </Container>
            </main>
            <AppAside fixed hidden>
              <DefaultAside/>
            </AppAside>
          </div>
          {/*<AppFooter>*/}
          {/*  <DefaultFooter/>*/}
          {/*</AppFooter>*/}
        </div>
      </LoginRequiredContainer>
    );
  }
}

export default DefaultLayout;
