import React from 'react';
import {createFragmentContainer} from "react-relay";
import {graphql} from "graphql";
import {Link} from "react-router-dom";


function TriggerReason({reason}) {
  if (!reason) {
    return 'Not available (deleted)'
  }
  if (reason.__typename === 'DataPullRunNode') {
    return <div>
      <Link to={`/system/data-pull-runs/${reason.id}`}>
        <i className="fas fa-arrow-right mr-2"/>
        Data pull
      </Link>
    </div>
  } else if (reason.__typename === 'IngestedFileNode') {
    return <div>
      <Link to={`/system/ingested-files/${reason.id}`}>
        Ingested File
      </Link>
    </div>
  }

  return <p className={'text-danger'}>Unknown</p>
}

export default createFragmentContainer(
    TriggerReason,
    {
      reason: graphql`
        fragment TriggerReason_reason on Node {
          id
          __typename
          ... on IngestedFileNode {
            file
            tags
            timestamp
            brand {
              ...SmallInlineBrandDisplay_brand
            }
            retailer {
              ...SmallInlineRetailerDisplay_retailer
            }
          }
          ... on DataPullRunNode {
            id
            state
            finishedDate
            dataPull {
              id
            }
          }
        }
      `
    }
)