/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SourcesModalQueryVariables = {
    unifiedId: string;
};
export type SourcesModalQueryResponse = {
    readonly getUnifiedProduct: {
        readonly id: string;
        readonly rawProducts: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly createdDate: unknown;
                    readonly sourceIngestionRun: {
                        readonly id: string;
                        readonly triggerReason: {
                            readonly id: string;
                            readonly " $fragmentRefs": FragmentRefs<"TriggerReason_reason">;
                        } | null;
                        readonly channel: {
                            readonly id: string;
                            readonly name: string;
                        };
                    } | null;
                    readonly sourceImportRun: {
                        readonly id: string;
                        readonly started: unknown;
                        readonly endTime: unknown | null;
                    } | null;
                } | null;
            } | null>;
        } | null;
    } | null;
};
export type SourcesModalQuery = {
    readonly response: SourcesModalQueryResponse;
    readonly variables: SourcesModalQueryVariables;
};



/*
query SourcesModalQuery(
  $unifiedId: ID!
) {
  getUnifiedProduct(id: $unifiedId) {
    id
    rawProducts {
      edges {
        node {
          id
          createdDate
          sourceIngestionRun {
            id
            triggerReason {
              __typename
              id
              ...TriggerReason_reason
            }
            channel {
              id
              name
            }
          }
          sourceImportRun {
            id
            started
            endTime
          }
        }
      }
    }
  }
}

fragment SmallInlineBrandDisplay_brand on BrandNode {
  id
  name
}

fragment SmallInlineRetailerDisplay_retailer on RetailerNode {
  id
  name
}

fragment TriggerReason_reason on Node {
  __isNode: __typename
  id
  __typename
  ... on IngestedFileNode {
    file
    tags
    timestamp
    brand {
      ...SmallInlineBrandDisplay_brand
      id
    }
    retailer {
      ...SmallInlineRetailerDisplay_retailer
      id
    }
  }
  ... on DataPullRunNode {
    id
    state
    finishedDate
    dataPull {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "unifiedId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "unifiedId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDate",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "IngestionChannelNode",
  "kind": "LinkedField",
  "name": "channel",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImportRunNode",
  "kind": "LinkedField",
  "name": "sourceImportRun",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "started",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SourcesModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnifiedProductNode",
        "kind": "LinkedField",
        "name": "getUnifiedProduct",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RawProductNodeConnection",
            "kind": "LinkedField",
            "name": "rawProducts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RawProductNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RawProductNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IngestionRunNode",
                        "kind": "LinkedField",
                        "name": "sourceIngestionRun",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "triggerReason",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "TriggerReason_reason"
                              }
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SourcesModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnifiedProductNode",
        "kind": "LinkedField",
        "name": "getUnifiedProduct",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RawProductNodeConnection",
            "kind": "LinkedField",
            "name": "rawProducts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RawProductNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RawProductNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IngestionRunNode",
                        "kind": "LinkedField",
                        "name": "sourceIngestionRun",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "triggerReason",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isNode"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "file",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "tags",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timestamp",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BrandNode",
                                    "kind": "LinkedField",
                                    "name": "brand",
                                    "plural": false,
                                    "selections": (v4/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RetailerNode",
                                    "kind": "LinkedField",
                                    "name": "retailer",
                                    "plural": false,
                                    "selections": (v4/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "IngestedFileNode",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "state",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "finishedDate",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DataPullNode",
                                    "kind": "LinkedField",
                                    "name": "dataPull",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "DataPullRunNode",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1824e9e6d286540d7dc22059146e76a0",
    "id": null,
    "metadata": {},
    "name": "SourcesModalQuery",
    "operationKind": "query",
    "text": "query SourcesModalQuery(\n  $unifiedId: ID!\n) {\n  getUnifiedProduct(id: $unifiedId) {\n    id\n    rawProducts {\n      edges {\n        node {\n          id\n          createdDate\n          sourceIngestionRun {\n            id\n            triggerReason {\n              __typename\n              id\n              ...TriggerReason_reason\n            }\n            channel {\n              id\n              name\n            }\n          }\n          sourceImportRun {\n            id\n            started\n            endTime\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment SmallInlineBrandDisplay_brand on BrandNode {\n  id\n  name\n}\n\nfragment SmallInlineRetailerDisplay_retailer on RetailerNode {\n  id\n  name\n}\n\nfragment TriggerReason_reason on Node {\n  __isNode: __typename\n  id\n  __typename\n  ... on IngestedFileNode {\n    file\n    tags\n    timestamp\n    brand {\n      ...SmallInlineBrandDisplay_brand\n      id\n    }\n    retailer {\n      ...SmallInlineRetailerDisplay_retailer\n      id\n    }\n  }\n  ... on DataPullRunNode {\n    id\n    state\n    finishedDate\n    dataPull {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '63a5f9d81d9a7d9914c3e706504d7ec0';
export default node;
