import React, {useState} from "react";
import {Button as ReactstrapButton} from "reactstrap";
import styles from './Button.module.scss';

function Button({loading, children, block, variant, removable = false, onRemove, ...props}) {
  const [displayRemoveButton, setDisplayRemoveButton] = useState(false);

  if (variant) {
    props.color = variant;
  }
  return <ReactstrapButton disabled={loading}
                           block={block}
                           onMouseEnter={e => removable && setDisplayRemoveButton(true)}
                           onMouseLeave={e => removable && setDisplayRemoveButton(false)}
                           {...props}>
    {displayRemoveButton && <i className={"fas fa-minus " + styles.removeTokenIcon} onClick={e => {
      e.stopPropagation();
      onRemove();
    }}/>}
    {loading && <i className="fas fa-circle-notch fa-spin mr-2"/>}
    {children}
  </ReactstrapButton>
}

export default Button;
